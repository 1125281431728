import React, { useState } from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";
import Booking from "../components/Booking/Booking";

// markup
const IndexPage = () => {
  // eslint-disable-next-line
  const [bookingActive, setBookingActive] = useState(true);
  // eslint-disable-next-line
  const [serviceChosen, setServiceChosen] = useState(true);
  // eslint-disable-next-line
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState(1);

  console.log("ableToGoBack", ableToGoBack);

  return (
    <Layout
      bookingActive={bookingActive}
      serviceChosen={serviceChosen}
      ableToGoBack={ableToGoBack}
      finishBookingStep={finishBookingStep}
      setFinishBookingStep={setFinishBookingStep}
    >
      <Seo title="Städfirma i Jönköping | Miljövänlig Städfirma - Städföretag Putso" />
      <Booking
        setBookingActive={setBookingActive}
        setServiceChosen={setServiceChosen}
        setAbleToGoBack={setAbleToGoBack}
        finishBookingStep={finishBookingStep}
        setFinishBookingStep={setFinishBookingStep}
      />
    </Layout>
  );
};

export default IndexPage;
